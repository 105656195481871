import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';

function Navbar({ className, children }) {
  const [showMenu, setShowMenu] = useState(false);
  const [changeLog, setChangeLog] = useState([]);

  const handleClick = (e) => {
    e.preventDefault();
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    fetch('/change-log.json')
      .then((data) => data.json())
      .then((data) => setChangeLog(data));
  }, []);

  const renderChangeLog = () => {
    return (
      <>
        <h3>Change Log</h3>
        <dl>
          {!changeLog.length ? (
            <p>No recent updates.</p>
          ) : (
            changeLog.map((v) => (
              <>
                <dt>{v.status.charAt(0).toUpperCase() + v.status.slice(1)}</dt>
                <dd>
                  <ul>
                    {v.log.map((v) => (
                      <li>{v}</li>
                    ))}
                  </ul>
                </dd>
              </>
            ))
          )}
        </dl>
      </>
    );
  };

  return (
    <nav className={`${styles.Nav} ${className || ''}`}>
      <div className={styles.menu} onClick={handleClick}>
        {showMenu ? (
          <FontAwesomeIcon
            icon={faClose}
            className={`${styles['menu-icon']} me-2`}
          />
        ) : (
          <FontAwesomeIcon
            icon={faBars}
            className={`${styles['menu-icon']} me-2`}
          />
        )}

        {showMenu && (
          <div className={styles['menu-items']}>{renderChangeLog()}</div>
        )}
      </div>
      <div className={styles.Logo}>
        <span className={styles.ThrasioLogo} />
        <div className={styles['gatekeeper-logo']}>
          <span>Gatekeeper</span>
          <FontAwesomeIcon icon={['fas', 'dungeon']} className="me-2" />
        </div>
      </div>
      {children}
    </nav>
  );
}

export default Navbar;
